<template>
  <component :is="teamData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="teamData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.errorTeam") }}
        <b-link class="alert-link" :to="{ name: 'apps-team-list' }">
          {{ $t("message.teamList") }}
        </b-link>
        {{ $t("message.forOtherTeams") }}
      </div>
    </b-alert>

    <template v-if="teamData">
      <div>
        <b-card>
          <!-- editar equipe -->
          <h2 class="mb-2">{{ $t("message.team.viewTeam") }}</h2>

          <b-row>
            <b-col md="3">
              <!-- multiple select -->
              <b-form-group label-for="nameTeams">
                <template v-slot:label>
                  {{ $t("message.tableHeader.name") }}
                </template>
                <b-form-input
                  readonly
                  id="basicInput"
                  v-model="teamData.name"
                />

                <!-- <v-select
                  class=""
                 
                  v-model="teamData.name"
                  :options="teamOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                /> -->
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="userId">
                <template v-slot:label>
                  {{ $t("message.team.managers") }}
                </template>

                <v-select
                  disabled
                  multiple
                  v-model="teamData.managers"
                  :options="userOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="task-users"
                  class="mt-0"
                />
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-form-group label-for="userId">
                <template v-slot:label>
                  {{ $t("message.team.members") }}
                </template>

                <v-select
                  disabled
                  multiple
                  v-model="teamData.members"
                  :options="userOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="task-users"
                  class="mt-0"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-md-12">
              <div class="text-right mt-2 mr-2 mb-2">
                <b-button
                  :to="{ name: 'apps-team-edit', params: { id: teamData.id } }"
                  variant="primary"
                  v-if="restrictions('button_edit_team')"
                >
                  {{ $t("message.buttons.edit") }}
                </b-button>
                <b-button
                  v-b-modal.modal-delete-confirmation
                  variant="outline-danger"
                  class="ml-1"
                  v-if="restrictions('button_delete_team')"
                >
                  {{ $t("message.buttons.delete") }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </div>

      <b-modal
        id="modal-delete-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text>
          {{ $t("message.deletAcount") }}
        </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-team-list' }"
            @click="deleteTeam()"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-modal>
    </template>
  </component>
</template>

<style>
.mt {
  margin-top: -0.3rem;
}
</style>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BCardText,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccessControl from "@core/utils/access-control";
import store from "@/store";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BCardText,
    BFormSelect,
    vSelect,
  },

  data() {
    return {
      //select \\
      userOptions: [],
      teamOptions: [],
      //multilingual i18n \\
      locale: this.$i18n.locale,
      userData: store.state.user.userData,
    };
  },

  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },

  async created() {
    var teamList = [];
    var teamId = [];
    await axios
      .get(`${URL_API}team/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          teamList.push(element);
          teamId.push(id);
          this.teamOptions.push({ label: teamList[i], value: teamId[i] });
        }
      });
    var userList = [];
    var userId = [];
    await axios
      .get(`${URL_API}user/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].email;
          const id = response.data[i].id;
          userList.push(element);
          userId.push(id);
          this.userOptions.push({
            label: userList[i],
            value: userId[i],
          });
        }
      });
  },

  methods: {
    updateTeams() {
      axios({
        method: "put",
        url: `${URL_API}team/${this.teamData.id}`,
        headers: getHeader(this.userData),
        data: this.teamData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },

    deleteTeam() {
      axios({
        method: "delete",
        url: `${URL_API}team/${this.teamData.id}`,
        headers: getHeader(this.userData),
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.deleted"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.deleted"),
          },
        });
      });
    },

    restrictions(value) {
      return AccessControl(value);
    },
  },

  setup() {
    const teamData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}team/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        teamData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          teamData.value = undefined;
        }
      });

    return {
      teamData,
      vSelect,
    };
  },
};
</script>
